import React from "react"
import Link from "gatsby-link"
import Layout from "../components/layout"

const PrivacyPolicy = props => (
  <Layout location={props.location}>
    <section className="bg black">
      <article className="full-page">
        <div className="body type--body">
          <header>
            {/* <a href="/" title="return to home page">
            <Logo />
          </a> */}
            <h1 className="type--header type--subheader">
              Viacom Privacy Policy and Your California Privacy Rights
            </h1>
            <p>
              <Link to="/terms-of-use" className="link--inline">
                Go to Terms of Use
              </Link>
            </p>
          </header>

          <p>Last Modified: January 1, 2020</p>

          <p>
            This Privacy Policy explains the privacy practices of Viacom
            International Inc. (and its brands, including MTV, Comedy Central,
            Paramount Networks, VH1, SnowGlobe, VidCon, Awesomeness, CMT,
            TVLand, Logo, and Bellator), Paramount Pictures Corporation, Spike
            Cable Networks Inc., BET Holdings LLC, Pluto Inc., and their
            controlled subsidiaries (collectively "Viacom", "we", "us", or
            "our"). Some of Viacom's Services are directed at children, in which
            case the privacy policy available at{" "}
            <a
              href="nick.com/legal/83acmp/privacy-policy"
              target="_blank"
              title="Go to nick privacy policy"
              className="link--inline"
              rel="noreferrer noopener"
            >
              nick.com/legal/83acmp/privacy-policy
            </a>{" "}
            applies. Viacom creates entertainment experiences that drive
            conversation and culture around the world through television, film,
            digital media, live events, merchandise, and other services. When
            you visit the websites, mobile and online applications or other
            products and services of our brands, attend our live events, view
            our content or advertisements, or contact our customer service
            (collectively, the "Services"), we may collect information from or
            about you.
          </p>
          <p>
            This Privacy Policy applies to all information about you that we
            collect in connection with the Services in the United States. While
            some of the information we describe here is technical, our goal is
            to make this policy straightforward, so please{" "}
            <a className="link--inline" href="#contact-us">
              contact us
            </a>{" "}
            if you have any questions.
          </p>
          <p>
            We may change this Privacy Policy from time to time. When we make
            changes, we will revise the date at the top of the policy and, in
            some cases, will notify you through the Services, such as on our
            homepage, or via email or other communication.
          </p>
          <p>
            If you are a California resident, in order to learn more about your
            California Privacy Rights, please see the California Privacy Notice{" "}
            <a className="link--inline" href="#california-privacy">
              section below
            </a>
            .
          </p>

          <h2 className="type--subheader" id="personal-info-collect">
            Personal Information We Collect
          </h2>

          <p>
            We collect information from and about you in connection with the
            Services. Some of this information may be considered "personal
            information," meaning it identifies you or your device or it is
            reasonably associated with you. We also collect, use, and disclose
            aggregated or deidentified data that does not reasonably identify
            you or your device and is not subject to this Privacy Policy.
          </p>

          <em>Information You Provide</em>
          <p>
            We collect information directly from you in a variety of ways. For
            example, we may collect information when you:
          </p>
          <ul>
            <li>
              Register for an account, participate in interactive areas of our
              Services, or fill out forms on our Services;
            </li>
            <li>
              Request additional information about our Services or sign up to
              receive news,information or special offers from us;
            </li>
            <li>Interact with us on social media;</li>
            <li>
              Purchase any product, subscription, or event ticket from us; 1
            </li>
            <li>Attend one of our events;</li>
            <li>
              Request information or assistance from us, including
              correspondence with our customer service teams;
            </li>
            <li>
              Participate in or respond to surveys or requests for opinions,
              feedback and preferences;
            </li>
            <li>
              Participate in events, screenings, contests, sweepstakes, or
              promotions.
            </li>
          </ul>
          <p>The types of information we collect directly from you include:</p>
          <ul>
            <li>
              Identifiers, such as your name, email address, phone number, and
              mailing address;
            </li>
            <li>Demographic information, such as your gender and age;</li>
            <li>
              Commercial information, such as payment and billing information;
              and
            </li>
            <li>
              Other information that relates to or is capable of being
              associated with you, such as passwords, personal preferences and
              interests, age, gender, and any other personal information you
              choose to provide.
            </li>
          </ul>
          <em>Information Collected Automatically</em>
          <p>
            We automatically collect personal information when you access and
            use our Services, including:
          </p>
          <ul>
            <li>
              Device and network identifiers such as your IP address, mobile
              advertising ID,media access control (MAC) address, and identifiers
              associated with browser cookies, web beacons and similar
              technologies we deploy on our Services;
            </li>
            <li>
              Internet or electronic network activity information, such as your
              Internet service provider (ISP), browser type and operating system
              version, language settings, access times, pages viewed, the routes
              by which you access our Services, and your use of any hyperlinks
              available within our Services; and
            </li>
            <li>
              Geolocation data, such as (i) information that identifies the
              precise location ofyour mobile device (when we have your consent
              to do so) and (ii) your IP address, which may be used to estimate
              your approximate location.
            </li>
          </ul>
          <em>Cookies and Similar Technologies</em>
          <p>
            We and our advertising and analytics service providers use cookies,
            which are small text files that help store user preferences and
            activity, similar technologies such as web beacons, pixels, ad tags,
            and SDKs, which are blocks of code provided by our partners that may
            be installed in our mobile and online applications, to recognize you
            when you visit our Services, and to collect information such as the
            number of visits, which features, pages, or content are popular, and
            to measure your browsing activities. These technologies allow us to
            optimize the operation of our Services, including by allowing us to
            help ensure our Services are functioning properly, improve the
            Services, and to provide, target, and measure advertisements.
          </p>
          <em>Information from Third Parties</em>
          <p>
            We may collect personal information about you from other sources so
            we can better understand you and provide you with services,
            advertising, and offers that may be of interest to you. For example,
            we may collect personal information about you from:
          </p>
          <ul>
            <li>
              Third-party social media services, such as Facebook, Twitter, and
              Google, thatyou use to interact with our Services (e.g., to create
              an account) or that allowyou to share information, but always in
              accordance with the authorization procedures and privacy settings
              you establish with such social media services;
            </li>
            <li>
              Television services (or "multichannel video programming
              distributors") or other third-party services, such as film
              exhibitors, that distribute our content;and
            </li>
            <li>
              Our unaffiliated partners, such as third-party interactive
              applications (e.g., mobile devices, third-party services, embedded
              audio and video players), co-branded partners and websites (e.g.,
              on our Facebook pages), our advertisers, and commercially
              available sources (e.g., data aggregators and public data sets).
            </li>
          </ul>

          <em>Personal Information We Derive</em>
          <p>
            We may derive information or draw inferences about you based on the
            information we, or our partners, collect. For example, we may make
            inferences that you are interested in a particular type of content
            or event so that we can show content or ads that are more relevant
            to your interests. We may also build a profile of you containing
            information that we, or third parties, may collect in order to
            identify you across sites, devices, and over time and may augment
            your profile with information derived from inferences described
            above.
          </p>
          <p>
            All of the information we collect about you may be combined and used
            for the purposes described in the "Use of Your Personal Information"
            section below.
          </p>

          <h2 className="type--subheader" id="personal-info-use">
            Use of Your Personal Information
          </h2>
          <p>
            We collect and use information about you for the following purposes,
            including to:
          </p>
          <ul>
            <li>
              Provide, maintain, and improve our Services, as well as to develop
              new content and features;
            </li>
            <li>
              Complete the transactions you request and perform our contractual
              obligations;
            </li>
            <li>Create, manage, and authenticate your account;</li>
            <li>Send notifications related to your account and purchases;</li>
            <li>
              Send promotional messages and newsletters such as via email, text
              messaging, or push notification;
            </li>
            <li>
              Enable you to participate in public platforms or other interactive
              features of the Services;
            </li>
            <li>Respond to customer service requests;</li>
            <li>Conduct internal research and development;</li>
            <li>
              Target advertisements to you both on and off of our Services;
            </li>
            <li>
              Analyze traffic and user activities on our Services, identify
              popular areas or features, and optimize and personalize the
              Services;
            </li>
            <li>Debug the Services; and</li>
            <li>
              Protect the rights and property of Viacom and others and comply
              with our legal obligations, including to detect, investigate, and
              prevent fraud and other illegal activities and to enforce our
              agreements
            </li>
          </ul>
          <p>
            We may use each of the categories of personal information we collect
            for all of the uses described above.
          </p>

          <h2 className="type--subheader" id="personal-info-disclosure">
            Disclosure of Your Personal Information
          </h2>
          <p>
            We share the personal information we collect in the limited
            circumstances described below.
          </p>

          <ul>
            <li>
              <u>With our Affiliates and Subsidiaries</u>. We disclose your
              personal information to other Viacom companies, subsidiaries and
              affiliates, for the purposes described in the "Use of Your
              Personal Information"{" "}
              <a className="link--inline" href="#personal-info-use">
                section above
              </a>
              .
            </li>
            <li>
              <u>With our Service Providers</u>. We share personal information
              with unaffiliated companies or individuals we hire or work with
              that perform services on our behalf, including customer support,
              web hosting, information technology, payment processing, event
              providers, direct mail and email distribution, contest,
              sweepstakes and promotion administration, and analytics services.
            </li>
            <li>
              <u>In Connection with a Corporate Transaction</u>. We may disclose
              or transfer personal information as part of, or during
              negotiations for, any purchase, sale, lease, merger, or any other
              type of acquisition, disposal, or financing involving our brands.
            </li>
            <li>
              <u>With our Professional Advisors</u>. We share personal
              information with our legal, financial, insurance and other
              advisors in connection with the corporate transactions described
              above or in connection with the management of our business and
              operations.
            </li>
            <li>
              <u>
                With Law Enforcement Authorities and Individuals Involved in
                Legal Proceedings
              </u>
              . We share personal information when it’s necessary for us to
              comply with applicable law or legal process, to respond to legal
              claims, or to protect our rights, property or personal safety of
              our users, employees, or thepublic.
            </li>
            <li>
              <u>With Your Consent or At Your Direction</u>. We share personal
              information with third parties when you ask us to do so or
              intentionally interact with a third party. For example, if you
              decide to participate in certain interactive areas or features of
              our Services, such as posting a comment on a public blog, you
              aredirecting us to share this information with other users of our
              Services.
            </li>
          </ul>

          <p>
            We do not sell your identifying information such as your name, email
            address, phone number, or postal address. Some of our brands share
            identifiers such as cookies and the advertising identifier
            associated with your mobile or internet-connected device with our
            advertising partners, and those partners use and share those
            identifiers to show advertisements that are targeted to your
            interests. Where a brand does this, it will give you the opportunity
            to opt out by clicking the "Do Not Sell My Personal Information"
            link on the home page of the relevant website, mobile and online
            application, or other service. Please note that when you use these
            tools on our websites, you will need to renew your choices if you
            clear your cookies or use a new browser or device.
          </p>
          <p>
            In addition to the disclosures above, we also disclose personal
            information to companies to provide analytics services and display
            advertisements on our Services. These providers may use cookies, web
            beacons, device identifiers, and other tracking technologies which
            collect information about your use of our Services. This information
            may be used by us and others to determine the popularity of our
            Services, deliver advertising and content targeted to your interests
            on our Services and on other websites, apps, and other services and
            to better understand your online activity. You can find more
            information about this practice or to opt out of your web browsing
            activity for interest-based advertising purposes, by visiting
            aboutads.info/choices. Your device may also include a feature that
            allows you to opt out of the use of information about your use of
            mobile and online applications for interest-based advertising
            purposes ("Limit Ad Tracking" on iOS devices or "Opt Out of
            Personalized Ads" on Android devices).
          </p>

          <p>
            Please visit{" "}
            <a
              className="link--inline"
              href="http://srp.viacom.com/sitefaq.html"
              title="Go to Viacom FAQ"
              target="_blank"
            >
              http://srp.viacom.com/sitefaq.html
            </a>{" "}
            for more information on the key third-party partners that we use to
            provide online behavioral advertising, sometimes called personalized
            advertising or tailored advertising, and your choices regarding the
            use of your information.
          </p>

          <h2 className="type--subheader" id="social-sharing">
            Social Sharing
          </h2>
          <p>
            The Services may allow you to share your personal information with
            social media platforms and other third parties. When you share in
            this manner, the privacy practices of the social media platform
            control who has access to your information and how it is treated by
            the social media platform. Please consult the privacy policies of
            any social media platform to which you share information in this
            manner to learn about the social media platform's data practices.
          </p>

          <h2 className="type--subheader" id="children-consent">
            Children's Content
          </h2>
          <p>
            Some of Viacom's Services are directed at children, in which case
            the privacy policy available at{" "}
            <a
              className="link--inline"
              href="nick.com/legal/83acmp/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
              title="Go to Nick privacy policy"
            >
              nick.com/legal/83acmp/privacy-policy
            </a>{" "}
            applies. The Viacom Services governed by this privacy policy are
            generally not intended for use by children.
          </p>
          <p>
            In cases where channels available through our general audience
            services are directed to children, we collect only a limited amount
            of personal information from those channels, as permitted by the
            Children's Online Privacy Protection Act. Specifically, we may
            collect IP address, device identifiers, certain unique ID numbers,
            and limited viewing information. We do not use, or allow our
            partners to use this information other than for purposes of
            supporting our internal operations, such as to provide children with
            access to features and activities on the Services, to customize
            content and improve our Services, or serve contextual advertising or
            limit the number of times a particular advertisement is seen. We
            never allow interest-based advertising on portions of our Services
            that are directed to children or where we know that the user is a
            child or a teen under 16.
          </p>

          <h2 className="type--subheader" id="your-rights">
            Your Rights and Choices
          </h2>
          <em>Marketing Communications</em>
          <p>
            You may opt out of receiving promotional communications from us by
            following the instructions in those messages. If you opt out, we may
            still send you non-promotional communications, such as emails about
            your account or orders from us.
          </p>
          <em>Location Data</em>
          <p>
            When you use any of our mobile and online applications that collect
            precise location information, you may be asked to consent to the
            collection of this information. You can change your preferences at
            any time through the settings on your mobile device.
          </p>
          <em>Mobile Push Notifications/Alerts</em>
          <p>
            With your consent, we may send promotional and non-promotional push
            notifications or alerts to your mobile device. You can deactivate
            these messages at any time by changing the notification settings on
            your mobile device.
          </p>

          <h2 className="type--subheader" id="california-privacy">
            California Privacy Notice
          </h2>
          <p>
            If you are a California resident, please review these additional
            privacy disclosures, which provide a summary of how we collect, use,
            and disclose personal information from you and also explains your
            rights under California law.
          </p>
          <em>
            Categories of Information We Collect, Use, and Disclose for Business
            Purposes
          </em>
          <p>
            We collect the following categories of personal information as
            described in the Personal Information We Collect section. We collect
            this information from you, your devices, third parties, or as
            otherwise described in this policy. We use these categories as
            described in the Use of Your Personal Information section above, and
            we disclose these categories of personal information for business
            purposes as described in the Disclosure of Your Personal Information
            section above:
          </p>
          <ul>
            <li>
              Identifiers, such as name, email address, mailing address, phone
              number, or IP address.
            </li>
            <li>Demographic information, such as your gender and age.</li>
            <li>
              Commercial information, such as records of purchases and payment
              information.
            </li>
            <li>
              Internet or other electronic network activity information, such as
              log data, and information about the devices and computers you use
              to access the Services.
            </li>
            <li>
              Geolocation data, such as GPS signals, device sensors, Wi-Fi
              access points, and cell tower IDs, where you have allowed access
              to that information.
            </li>
            <li>Electronic, visual, or similar information, such as photos.</li>
            <li>
              Other information you provide, such as messages on discussion
              boards or responses to surveys.
            </li>
            <li>
              Inferences drawn from any of the above, including about the
              content or ads that may be of interest to you.
            </li>
          </ul>

          <em>Your Rights</em>
          <p>
            Under the California Consumer Privacy Protection Act (CCPA),
            California residents have the right to request access to or deletion
            of their personal information, to request additional details about
            our information practices, to opt out of the "sale" of their
            personal information, and to not be discriminated against for
            exercising these rights. Below we explain how you may exercise these
            rights. If you need further assistance regarding your rights, please
            contact us at Privacy@viacom.com, and we will consider your request
            in accordance with applicable laws.
          </p>

          <h3>
            <u>Access</u>
          </h3>
          <p>
            You may request access to the specific pieces of personal
            information we have about you. You may also request additional
            details about our information practices, including the categories of
            personal information we collect, the sources of information, the
            types of third parties we share information with, the types of
            personal information we share for business purposes, and details
            about the information we have sold, if any. You may request access
            by visiting{" "}
            <a
              className="link--inline"
              href="https://viacomprivacy.com"
              target="_blank"
              rel="noreferrer noopener"
              title="Go to Viacom site"
            >
              viacomprivacy.com
            </a>
            .
          </p>

          <h3>
            <u>Editing and Deletion</u>
          </h3>
          <p>
            You may review, modify, and delete your personal information by
            visiting{" "}
            <a
              className="link--inline"
              href="https://viacomprivacy.com"
              target="_blank"
              rel="noreferrer noopener"
              title="Go to Viacom site"
            >
              viacomprivacy.com
            </a>
            .
          </p>
          <p>
            Upon receipt of your request to exercise your rights to access and
            delete your personal information, we will verify your identity to
            protect your security and personal information. After submitting a
            request, please monitor your email for verification instructions.
            Note that even if we honor your request to delete your information,
            we may retain certain information as required or permitted by law.
          </p>

          <h3>
            <em>Do Not Sell My Personal Information</em>
          </h3>
          <p>
            As described above, some of our brands may share your personal
            information with advertising partners so that they can show
            advertisements on our properties, and these advertisements may be
            targeted to your interests, which may constitute a "sale" under
            California law. Where a brand does this, it will give you the option
            to opt out of disclosures for these purposes by following the "Do
            Not Sell My Personal Information" link on the homepage or download
            page of the relevant website, mobile app, or other service.
          </p>

          <h2 className="type--subheader" id="european-residents">
            Information for European Residents
          </h2>
          <p>
            The Services are operated in the United States. If you are located
            in the European Union or elsewhere outside of the United States,
            please be aware that any information you provide to us will be
            transferred to the United States. By using the Services,
            participating in any of our Services and/or providing us with your
            information, you consent to this transfer.
          </p>
          <p>
            We and our service providers may transfer your personal information
            to, or store or access it in, other countries where the laws may not
            provide levels of protection for your personal information
            equivalent the protection provided by the laws of the European Union
            or elsewhere outside of the United States. When we do this, we take
            steps to ensure that your personal information receives an
            appropriate level of protection through contractual requirements
            imposed on the recipient of the information (you can obtain a copy
            of these clauses by contacting us at{" "}
            <a
              className="link--inline"
              href="mailto:DataPrivacyRights@viacom.com"
              title="email DataPrivacyRights@viacom.com"
            >
              DataPrivacyRights@viacom.com
            </a>
            ).
          </p>

          <em>Questions or Complaints from European Residents</em>

          <p>
            If you have a question about our Privacy Policy, data practices or
            the choices available to you, please contact us using the
            information below. If we are not able to address your concern and
            you are a resident of the EEA, you have the right to lodge a
            complaint with the Data Protection Authority where you live, work,
            or where the issue took place. For contact details of your local
            Data Protection Authority, please see{" "}
            <a
              className="link--inline"
              href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
              target="_blank"
              rel="noreferrer noopener"
            >
              http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm
            </a>
            .
          </p>

          <h2 className="type--subheader" id="contact-us">
            Contact Us
          </h2>
          <p>
            If you have any questions about this Privacy Policy, you may call us
            at 1-888-851-8412 or email us at{" "}
            <a
              className="link--inline"
              href="mailto:Privacy@viacom.com"
              title="email Privacy@viacom.com"
            >
              Privacy@viacom.com
            </a>{" "}
            with the subject line "Privacy Rights".
          </p>

          <em>For residents of Europe:</em>
          <p>Data Protection Officer</p>
          <a
            className="link--inline"
            href="mailto:DataPrivacyRights@viacom.com"
            title="email DataPrivacyRights@viacom.com"
          >
            DataPrivacyRights@viacom.com
          </a>
        </div>
      </article>
    </section>
  </Layout>
)

export default PrivacyPolicy
